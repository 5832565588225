import React from 'react';
import { Link } from 'react-router-dom';
import '../App.scss';
import books from './books.js';
import { renderBooks } from './readings';

const Now = () => (
    <div className="now">
        <div className="now_section">
            <h2 className="title">What I'm doing now</h2>
        </div>
        <div className="now_section">
            <h3>Personal <span role="img" aria-label="emoji">🐶</span></h3>
            <p>Anna and I moved in and have been living together. That's the biggest change so far this year. Living together has deepen our relationship and taught us how to communicate more effectively and interact more meaningfully with each other. Other than that, I think Kiba, our mini schnauzer, is probably the happiest and biggest benefactor. He now has both of us in one place and doesn't have to go back and forth between different places feeling like having divorced parents.</p>

            <h3>Work <span role="img" aria-label="emoji">👨‍💻</span></h3>
            <p>I've been working at SAP as a UI Engineer for over four years now, which feels like an eternity in Silicon Valley life. The company had a round of layoff earlier this year, which unfortunately, affected around 3,000 people. Our team was  lucky. Nobody got affected. The job market has been tough for the past year. It feels like surfing a big wave. You are never safe and has to go with the punches.</p>

            <h3>Travels <span role="img" aria-label="emoji">✈️</span></h3>
            <p>Last year ended up being quite eventful. I was able to take my parents and fiancé to Japan - it was their first time! They were the happiest exploring and I shared much of this joy by simply seeing them having a good time. Anna and I also ended up traveling around Asia – Thailand, Vietnam, China, and Japan for a second time.</p>

            <h3>Running <span role="img" aria-label="emoji">🏃‍♂️</span></h3>
            <p>Last year I trained the best I have in a long time and ended up running my fastest marathon ever - 3 hours 32 minutes. That made it my 10th marathon. I couldn’t be prouder of it. I never thought I would be running this many when I started back in 2012 running the San Francisco Marathon - that was a tough race. I think I ended up enjoying how peaceful running can get. I have no further plans of running another marathon in the near future. I’m planning to take a most needed break from running to focus on other parts of my life.</p>

            <h3>Study abroad reunion <span role="img" aria-label="emoji">🙂</span></h3>
            <p>We rarely plan reunions nowadays since everybody have their own travel plans. We let things happen naturally. It's become more difficult as years go by. This was expected.</p>

            <h3>Writings <span role="img" aria-label="emoji">📝</span></h3>
            <p>I am experimenting writing in a private journal, but my blog still remains online: <a rel='noreferrer noopener' href="https://notyourtypicalasian.com" target="_blank"><strong>www.notyourtypicalasian.com</strong></a>. I will come back to blogging at some point in the near future.</p>
        </div>
        <div className="now_section">
            <p><em>Last updated March 10, 2025.</em></p>
        </div>
        <div className="now_section">
            <div className="reads">
                <h3>Currently reading <span role="img" aria-label="emoji">📚</span></h3>
                <p><Link to="/readings">Complete reading list</Link></p>
            </div>
            <div>
                {renderBooks(books, true)}
            </div>
        </div>
    </div>
)

export default Now;